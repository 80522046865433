export default function registerSecurePrivacyListeners(
  browserViewerId: string,
  cookieName: string,
  iframeSelector: string,
) {
  let iframe: HTMLIFrameElement | null = null;

  const blockCookies = () => {
    document.cookie = 'block_cookies = 1';
    iframe?.contentWindow?.postMessage({ type: 'deleteCookie', browserViewerId }, '*');
  };

  const setCookies = () => {
    document.cookie = 'block_cookies = 1;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    iframe?.contentWindow?.postMessage({ type: 'setCookie', browserViewerId }, '*');
  };

  const handleCookies = () => {
    // try to get from localStorage
    const spConsent = localStorage.getItem('sp_consent');
    let securePrivacy: (typeof window.sp)['allGivenConsents'];
    if (spConsent) {
      securePrivacy = JSON.parse(spConsent).AllConsents;
    } else {
      // otherwise pull from sp object
      securePrivacy = window.sp.allGivenConsents;
    }
    // No consents have been given, do not set cookies.
    // Timeout will handle when SP is off.
    if (!securePrivacy) {
      return;
    }

    // When secure privacy rescans our website,
    // Our cookies are recategorized under this Compliance Type.
    const backupComplianceType = 'Nazwa.pl';
    const bbCookie = securePrivacy
      .map((s) => JSON.parse(s.PluginPreferences as string))
      .flat()
      .find((p) => p.ComplianceType === cookieName || p.ComplianceType === backupComplianceType);
    // if sp blocking for bbCookie is turned on, block cookies
    if (bbCookie && bbCookie.IsBlockEnabled) {
      blockCookies();
    } else {
      setCookies();
    }
  };

  ['sp_privacy_banner_save', 'sp_cookie_banner_save'].forEach((event) =>
    window.addEventListener(event, handleCookies),
  );

  let spTimeout: ReturnType<typeof setTimeout> | null = null;
  window.addEventListener('message', function readyListener(e) {
    if (e.data.type === 'iframeReady') {
      window.removeEventListener('message', readyListener);
      iframe = document.querySelector(iframeSelector);

      // if sp is loaded and in region with banner
      if (window.sp && window.sp.activeDomainBanner) {
        // this call ensures we're pulling any existing consent
        handleCookies();
        return;
      }
      // wait for sp to be loaded,
      // if we're in region without banner, sp_init is never fired,
      // set cookies by default.
      spTimeout = setTimeout(function waitForSecurePrivacy() {
        if (!window.sp) {
          spTimeout = setTimeout(waitForSecurePrivacy, 3000);
          return;
        }

        if (window.sp.activeDomainBanner) {
          return;
        }
        setCookies();
      }, 3000);
    }
  });

  window.addEventListener(
    'sp_init',
    () => {
      if (spTimeout) {
        // sp banner is loaded, stop cookies by default
        // above event listeners will handle cookies.
        clearTimeout(spTimeout);
        spTimeout = null;
      }
    },
    false,
  );
}
