import registerSecurePrivacyListeners from '@/helpers/securePrivacyHelpers';
import { autoInjectReactApp } from '@/helpers/documentIsReady';

type SecurePrivacyListenersProps = {
  browserViewerId: string;
  cookieName: string;
  iframeSelector: string;
};

autoInjectReactApp(
  'SecurePrivacyListeners',
  function injectSecurePrivacyListeners(_: HTMLElement, props: SecurePrivacyListenersProps | null) {
    if (!props) {
      console.error('Could not load props for SecurePrivacyListeners');
      return;
    }
    registerSecurePrivacyListeners(props.browserViewerId, props.cookieName, props.iframeSelector);
  },
);
