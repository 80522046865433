/**
 * Resolve when the document is ready.
 */
export async function documentIsReady(): Promise<void> {
  if (document.readyState === 'complete') {
    // document is already ready
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    document.addEventListener('DOMContentLoaded', () => {
      resolve();
    });
  });
}

/**
 * Auto inject an application via query-for-props
 *
 * This function will search for an element with a data-app
 * attribute for the app name. It then will grab an ID to be
 * used to find the props (if any). This supports multiple instances
 * of an app but make sure the prop IDs are unique unless they all
 * share the same details.
 *
 * Once it has found the app's container and the props, it will call
 * the injectAppFunction with the container and the props. From there
 * you can inject the app as needed.
 *
 * @example
 *   <div data-app="MyApp" data-props="MyAppProps"></div>
 *   <script type="application/json" id="MyAppProps">{"myProps": 1234}</script>
 *
 * @param appName The app name to look for in the DOM
 * @param injectAppFunction Function which injects the react
 *   app to the container with props
 */
export async function autoInjectReactApp<T = Record<string, unknown>>(
  appName: string,
  injectAppFunction: (container: HTMLElement, props: null | T) => void,
) {
  await documentIsReady();
  const nodes = document.querySelectorAll(`[data-app="${appName}"]`) as NodeListOf<HTMLElement>;
  if (nodes.length === 0) {
    console.warn(`Could not find app ${appName} to inject`);
    return;
  }

  nodes.forEach((appContainer) => {
    const propsId = appContainer.dataset.props;
    const appProps = propsId ? document.getElementById(propsId) : null;
    let props = null;
    if (appProps) {
      try {
        props = JSON.parse(appProps.textContent ?? '');
      } catch (error) {
        console.error(`Could not parse ${appName}`, appProps.textContent, error);
      }
    }
    injectAppFunction(appContainer, props);
  });
}
